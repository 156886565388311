

import React,{useEffect,useState,useRef} from 'react'
import { useParams } from 'react-router-dom';


import authFetch from '../axios';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { Tooltip } from 'primereact/tooltip';
// import { Dropdown } from 'primereact/dropdown';

const SingleAdhoc = () => {
    const {id} = useParams();
    const [search,setSearch] = useState("");
    const [page,setPage] =useState(1)
    const [tot,setTot] = useState(null)
      const [basicFirst, setBasicFirst] = useState(0);
      // eslint-disable-next-line
    const [basicRows, setBasicRows] = useState(20);
    const onBasicPageChange = (event) => {
      setBasicFirst(event.first);
      setPage(event.page + 1);
    }  
// eslint-disable-next-line
    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(true);
    // eslint-disable-next-line
    const [filters1, setFilters1] = useState(null);
// eslint-disable-next-line
    const [globalFilterValue1, setGlobalFilterValue1] = useState('');
    const [products, setProducts] = useState({});
    const [products2, setProducts2] = useState([]);
    const [type, setType] = useState('pending');

//     const typeOptions=[{
//         name:"pending",
//         id:"pending"
//     },

//     {
//         name:"approved",
//         id:"approved"
//     },

//     {
//         name:"queried",
//         id:"queried"
//     },

//     {
//         name:"printed",
//         id:"printed"
//     },
// ]
    const clearFilter1 = () => {

           setSearch((prev)=>{
            return ''
           })
           setType((prev)=>{
            return 'pending'
           })
           setPage(1);
           window.location.reload()
    }
    const renderHeader1 = () => {
        return (
            <div className=" hidden md:flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearFilter1} />
              
    
{/*     
    <Dropdown value={userState} options={state} onChange={ changeState} placeholder="Select State" optionLabel="State_Name" optionValue="State_ID"/>
    
    <Dropdown value={userLga} options={lga} onChange={(e) => setUserLga(e.value)} placeholder="Select lga" optionLabel="LGA_Name" optionValue="LGA_ID"/> */}
       
   
    
    
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={search} onChange={(e)=>setSearch(e.target.value)} placeholder="Keyword Search" />
                </span>
            </div>
        )
    }
    const header1 = renderHeader1();
    const dt = useRef(null);
     
    useEffect(() => {
      const getDatz=async ()=>{
  // const statz = await axios.get('https://api.verxid.site/bt-mdm/get-device');
  // console.log(statz.data.results)
  const statz1 = await authFetch.get(`/users/single/${id}`);
//   console.log(statz1.data)
  // setMyStatz(statz.data.mtn);
  setProducts(statz1.data)

      setLoading1(false)
      }

    
             getDatz();       
  
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps
  


  useEffect(() => {
    const getDatz2=async ()=>{
        setLoading2(true)
      // const statz = await axios.get('https://api.verxid.site/bt-mdm/get-device');
      // console.log(statz.data.results)
      const statz1 = await authFetch.get(`/users/adhoc/registrations/?search=${search}&result_per_page=20&page=${page}&adhoc_id=${id}`);
      console.log(statz1.data)
      // setMyStatz(statz.data.mtn);
      setProducts2(statz1.data.result)
      setTot(statz1.data.pagination.total)
          setLoading2(false)
          }
            
           getDatz2();         

}, [id,page,type]); // eslint-disable-line react-hooks/exhaustive-deps



  return (
    <>
<div className="surface-card p-4 shadow-2 border-round my-2">



        <div className="flex flex-column md:flex-row md:flex-wrap pt-2 border-top-1 border-bottom-1 border-green-300">
         
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">UserName</div>
            <div className="text-900">{products&&products.UserName}</div>
            
        </div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Phone Number</div>
            <div className="text-900">{products&&products.Phone_No}</div>
            {/* <div className="text-900">Elliot Alderson</div> */}
        </div>
        <div className="col-12 md:col-5 p-3">
            <div className="text-500 font-medium mb-2">Email Address</div>
            <div className="text-900">{products&&products.Email}</div>
            {/* <div className="text-900">Elliot Alderson</div> */}
        </div>
        <div className="col-12 md:col-2 p-3">
          
            <div className="text-900">registrations</div>
            <div className="text-900">{products &&products.registrations}</div>
        </div>
     
        </div>
        
      
       
       
       
    </div>


    <div className='grid mt-2'>
     <div className="col-12 lg:col-12">
                <div className="card border-round shadow-2 p-3 ">
                <div className="mb-3 flex align-items-center justify-content-between p-3">
        <span className="text-xl font-medium text-900">Registration List</span>
        <div className="flex align-items-center export-buttons">
            {/* <Button type="button" icon="pi pi-file" onClick={() => exportCSV(false)} className="mr-2" data-pr-tooltip="CSV" /> */}
           {/* <Link to={`/registrars-new`}>
           <Button type="button" icon="pi pi-user" label='Create new' className="p-button-success mr-2"  />
           </Link>  */}
            {/* <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning mr-2" data-pr-tooltip="PDF" /> */}
            {/* <Button type="button" icon="pi pi-filter" onClick={() => exportCSV(true)} className="p-button-info ml-auto" data-pr-tooltip="Selection Only" /> */}
        </div>
       
    </div>
             <DataTable value={products2} 
             ref={dt}
                //   filters={filters1}
                    loading={loading2}
                    stripedRows
                     responsiveLayout="stack"
                     header={header1}
                    //  paginator
                    //  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    //  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={5} rowsPerPageOptions={[5,10,50]}
                     
                        >
                        {/* <Column field="id" header="Device Id"></Column> */}
                            {/* <Column field="name" header="Certificate Number"></Column> */}
                            <Column field="Name" header="Name"></Column>    
                            <Column field="Certificate_No" header="Certificate_No"></Column>
                            <Column field="Date_Registerred" header="Date_Registerred"></Column>
                           {/* <Column field="User_ID" header="Id"></Column> */}
                        {/* <Column field="" header="state" body={statusBodyTemplate4} /> */}
                        {/* <Column field="" header="Actions" body={statusBodyTemplate5} />
                        <Column field="" header="" body={statusBodyTemplate6} />
                        <Column field="" header="" body={statusBodyTemplate7} /> */}
                        {/* <Column field="" header="" body={statusBodyTemplate8} /> */}
                        {/* <Column field="" header="" body={statusBodyTemplate9} /> */}
                    </DataTable>
                    <Tooltip target=".export-buttons>button" position="bottom" />
                    <Paginator  first ={basicFirst} rows={basicRows} totalRecords={tot}  onPageChange={onBasicPageChange}></Paginator>
                </div>
            </div>
                     
            {/* <Toast ref={toast} />  */}
           
</div> 
          </>

  )
}

export default SingleAdhoc;